var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout-item md-size-100"},[(_vm.editedUnit)?_c('md-card',[_c('md-card-header',{staticClass:"card-header",attrs:{"data-background-color":"blue"}},[_c('div',{staticClass:"card-header-info"},[_c('h4',{staticClass:"title"},[_vm._v(" Изменить статы юнита "+_vm._s(_vm.editedUnit.name)+" ("+_vm._s(_vm.professionsById[_vm.editedUnit.attribute.professionId])+") ")])])]),_c('ValidationObserver',{ref:"updateUnitForm",attrs:{"slim":true}},[_c('md-card-content',[_c('div',{staticClass:"md-layout md-gutter"},[_c('ValidationProvider',{staticClass:"md-layout-item md-large-size-25",attrs:{"mode":"passive","tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("ХП")]),_c('md-input',{attrs:{"disabled":_vm.isLoaderVisible},on:{"focus":reset},model:{value:(_vm.editedUnit.attribute.healthPoints),callback:function ($$v) {_vm.$set(_vm.editedUnit.attribute, "healthPoints", $$v)},expression:"editedUnit.attribute.healthPoints"}})],1)]}}],null,false,2841018000)}),_c('ValidationProvider',{staticClass:"md-layout-item md-large-size-25",attrs:{"mode":"passive","tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Атака")]),_c('md-input',{attrs:{"disabled":_vm.isLoaderVisible},on:{"focus":reset},model:{value:(_vm.editedUnit.attribute.damage),callback:function ($$v) {_vm.$set(_vm.editedUnit.attribute, "damage", $$v)},expression:"editedUnit.attribute.damage"}})],1)]}}],null,false,4074513994)}),_c('ValidationProvider',{staticClass:"md-layout-item md-large-size-25",attrs:{"mode":"passive","tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Защита")]),_c('md-input',{attrs:{"disabled":_vm.isLoaderVisible},on:{"focus":reset},model:{value:(_vm.editedUnit.attribute.defence),callback:function ($$v) {_vm.$set(_vm.editedUnit.attribute, "defence", $$v)},expression:"editedUnit.attribute.defence"}})],1)]}}],null,false,843861031)}),_c('ValidationProvider',{staticClass:"md-layout-item md-large-size-25",attrs:{"mode":"passive","tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Скорость")]),_c('md-input',{attrs:{"disabled":_vm.isLoaderVisible},on:{"focus":reset},model:{value:(_vm.editedUnit.attribute.speed),callback:function ($$v) {_vm.$set(_vm.editedUnit.attribute, "speed", $$v)},expression:"editedUnit.attribute.speed"}})],1)]}}],null,false,65986042)}),_c('ValidationProvider',{staticClass:"md-layout-item md-large-size-25",attrs:{"mode":"passive","tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Шанс крита")]),_c('md-input',{attrs:{"disabled":_vm.isLoaderVisible},on:{"focus":reset},model:{value:(_vm.editedUnit.attribute.criticalChance),callback:function ($$v) {_vm.$set(_vm.editedUnit.attribute, "criticalChance", $$v)},expression:"editedUnit.attribute.criticalChance"}})],1)]}}],null,false,2093302260)}),_c('ValidationProvider',{staticClass:"md-layout-item md-large-size-25",attrs:{"mode":"passive","tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Инициация")]),_c('md-input',{attrs:{"disabled":_vm.isLoaderVisible},on:{"focus":reset},model:{value:(_vm.editedUnit.attribute.initiative),callback:function ($$v) {_vm.$set(_vm.editedUnit.attribute, "initiative", $$v)},expression:"editedUnit.attribute.initiative"}})],1)]}}],null,false,688887095)}),_c('ValidationProvider',{staticClass:"md-layout-item md-large-size-25",attrs:{"mode":"passive","tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Дальность атаки")]),_c('md-input',{attrs:{"disabled":_vm.isLoaderVisible},on:{"focus":reset},model:{value:(_vm.editedUnit.attribute.attackRange),callback:function ($$v) {_vm.$set(_vm.editedUnit.attribute, "attackRange", $$v)},expression:"editedUnit.attribute.attackRange"}})],1)]}}],null,false,1898632865)}),_c('ValidationProvider',{staticClass:"md-layout-item md-large-size-25",attrs:{"mode":"passive","tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Коэф.умений")]),_c('md-input',{attrs:{"disabled":_vm.isLoaderVisible},on:{"focus":reset},model:{value:(_vm.editedUnit.attribute.skillQuotient),callback:function ($$v) {_vm.$set(_vm.editedUnit.attribute, "skillQuotient", $$v)},expression:"editedUnit.attribute.skillQuotient"}})],1)]}}],null,false,1905297796)})],1),_c('div',{staticClass:"buttons-wrap"},[_c('md-button',{staticClass:"md-raised md-success",attrs:{"disabled":_vm.isLoaderVisible},on:{"click":_vm.updateUnit}},[_vm._v(" Сохранить ")]),_c('md-button',{staticClass:"md-raised md-danger",on:{"click":_vm.cancel}},[_vm._v(" Отмена ")])],1)])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }