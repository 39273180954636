<template>
  <div class="md-layout-item md-size-100">
    <md-card v-if="editedUnit">
      <md-card-header data-background-color="blue" class="card-header">
        <div class="card-header-info">
          <h4 class="title">
            Изменить статы юнита {{ editedUnit.name }} ({{
              professionsById[editedUnit.attribute.professionId]
            }})
          </h4>
        </div>
      </md-card-header>

      <ValidationObserver ref="updateUnitForm" :slim="true">
        <md-card-content>
          <div class="md-layout md-gutter">
            <ValidationProvider
              v-slot="{ errors, reset }"
              class="md-layout-item md-large-size-25"
              mode="passive"
              tag="div"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>ХП</label>
                <md-input
                  v-model="editedUnit.attribute.healthPoints"
                  :disabled="isLoaderVisible"
                  @focus="reset"
                />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              class="md-layout-item md-large-size-25"
              mode="passive"
              tag="div"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Атака</label>
                <md-input
                  v-model="editedUnit.attribute.damage"
                  :disabled="isLoaderVisible"
                  @focus="reset"
                />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              class="md-layout-item md-large-size-25"
              mode="passive"
              tag="div"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Защита</label>
                <md-input
                  v-model="editedUnit.attribute.defence"
                  :disabled="isLoaderVisible"
                  @focus="reset"
                />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              class="md-layout-item md-large-size-25"
              mode="passive"
              tag="div"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Скорость</label>
                <md-input
                  v-model="editedUnit.attribute.speed"
                  :disabled="isLoaderVisible"
                  @focus="reset"
                />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              class="md-layout-item md-large-size-25"
              mode="passive"
              tag="div"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Шанс крита</label>
                <md-input
                  v-model="editedUnit.attribute.criticalChance"
                  :disabled="isLoaderVisible"
                  @focus="reset"
                />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              class="md-layout-item md-large-size-25"
              mode="passive"
              tag="div"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Инициация</label>
                <md-input
                  v-model="editedUnit.attribute.initiative"
                  :disabled="isLoaderVisible"
                  @focus="reset"
                />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              class="md-layout-item md-large-size-25"
              mode="passive"
              tag="div"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Дальность атаки</label>
                <md-input
                  v-model="editedUnit.attribute.attackRange"
                  :disabled="isLoaderVisible"
                  @focus="reset"
                />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              class="md-layout-item md-large-size-25"
              mode="passive"
              tag="div"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Коэф.умений</label>
                <md-input
                  v-model="editedUnit.attribute.skillQuotient"
                  :disabled="isLoaderVisible"
                  @focus="reset"
                />
              </md-field>
            </ValidationProvider>
          </div>

          <div class="buttons-wrap">
            <md-button
              class="md-raised md-success"
              :disabled="isLoaderVisible"
              @click="updateUnit"
            >
              Сохранить
            </md-button>

            <md-button class="md-raised md-danger" @click="cancel">
              Отмена
            </md-button>
          </div>
        </md-card-content>
      </ValidationObserver>
    </md-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      editedUnit: null,
    };
  },

  computed: {
    ...mapGetters("professions", ["professionsById"]),
    ...mapState(["isLoaderVisible"]),
    ...mapState("professions", ["professions"]),
    ...mapState("units", ["selectedUnit"]),
  },

  async mounted() {
    if (!this.selectedUnit) {
      this.$router.push("/units");
      return;
    }

    this.showLoader(true);

    try {
      if (!this.professions.length) {
        await this.getProfessions();
      }

      this.editedUnit = JSON.parse(JSON.stringify(this.selectedUnit));
    } catch (error) {
      this.$router.push("/units");
    }

    this.showLoader(false);
  },

  methods: {
    ...mapActions("professions", ["getProfessions"]),
    ...mapActions("units", ["getUnits", "setUnitAttributes"]),
    ...mapMutations({
      setSelectedUnit: "units/SET_SELECTED_UNIT",
      showLoader: "SET_SHOW_LOADER",
    }),

    cancel() {
      this.showLoader(true);
      this.setSelectedUnit(null);
      this.showLoader(false);
      this.$router.push("/units");
    },

    async updateUnit() {
      const isValid = await this.$refs.updateUnitForm.validate();
      if (!isValid) {
        return;
      }

      this.showLoader(true);

      try {
        await this.setUnitAttributes(this.editedUnit.attribute);
        this.$router.push("/units");
      } catch (error) {
        this.showLoader(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.md-success {
  margin-right: 10px;
}
</style>